// react
import { useState } from "react";
import { useMaterialUIController } from "/context";

// next
import dynamic from "next/dynamic";
import Image from 'next/image';
import { useRouter } from 'next/router';

// Utils
import formatTimeFriendly from "/utils/formatTimeFriendly";
import validateEmail from "/utils/validateEmail";
import validatePhone from "/utils/validatePhone";

// Images
import bgImage from "/assets/img/bg-contact-us-section-desktop.jpg";

// Config
import config from "/config";

// font
import { robotoSlab } from "/utils/fonts";

// MUI
const Container = dynamic(() => import("@mui/material/Container"), { loading: () => null });
const FormControl = dynamic(() => import("@mui/material/FormControl"), { loading: () => null });
const Grid = dynamic(() => import("@mui/material/Grid"), { loading: () => null });
const InputAdornment = dynamic(() => import("@mui/material/InputAdornment"), { loading: () => null });
const Tooltip = dynamic(() => import("@mui/material/Tooltip"), { loading: () => null });

// MUI Icons
const DoneRounded = dynamic(() => import("@mui/icons-material/DoneRounded"), { loading: () => null });
const ErrorRounded = dynamic(() => import("@mui/icons-material/ErrorRounded"), { loading: () => null });
const Facebook = dynamic(() => import('@mui/icons-material/Facebook'), { loading: () => null });
const Twitter = dynamic(() => import('@mui/icons-material/Twitter'), { loading: () => null });
const YouTube = dynamic(() => import('@mui/icons-material/YouTube'), { loading: () => null });
const Instagram = dynamic(() => import('@mui/icons-material/Instagram'), { loading: () => null });
const Language = dynamic(() => import('@mui/icons-material/Language'), { loading: () => null });

// PRO
const MDBox = dynamic(() => import('/components/MDBox'), { loading: () => null });
const MDButton = dynamic(() => import('/components/MDButton'), { loading: () => null });
const MDInput = dynamic(() => import('/components/MDInput'), { loading: () => null });
const MDSnackbar = dynamic(() => import('/components/MDSnackbar'), { loading: () => null });
const MDTypography = dynamic(() => import('/components/MDTypography'), { loading: () => null });

function ContactUs() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  // location
  const router = useRouter();
  // values
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  // process
  const [snackbar, setSnackbar] = useState({});

  return (
    <>
      <MDBox component="section" py={{ xs: 0, lg: 6 }}>
        <Container sx={{ padding: 1 }}>
          <Grid container item px={{ xs: 0, lg: 6 }}>
            <MDBox
              width="100%"
              bgColor={darkMode ? "dark" : "white"}
              borderRadius="xl"
              shadow="xl"
              mb={6}
              sx={{ overflow: "hidden" }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} lg={7}>
                  <MDBox component="form" p={2} method="post">
                    <MDBox px={3} py={{ xs: 2, sm: 6 }}>
                      <MDTypography variant="h2" mb={1} className={robotoSlab.className}>
                        Say Hi!
                      </MDTypography>
                      <MDTypography variant="body1" color="text" mb={1}>
                        Let's start a discussion.
                      </MDTypography>
                    </MDBox>
                    <MDBox pt={0} pb={3} px={3}>
                      <Grid container>
                        <Grid item xs={12} pr={1} mb={3}>
                          <MDInput
                            variant="standard"
                            label="My name is"
                            placeholder="Full Name"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            value={name}
                            required
                            onChange={(e) => setName(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} pr={1} mb={3}>
                          <FormControl sx={{ width: "100%" }}>
                            <MDInput
                              variant="standard"
                              label="Email"
                              placeholder="Your email address"
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                              value={email}
                              required
                              onChange={(e) => setEmail(e.target.value)}
                              InputProps={{
                                endAdornment: (
                                  email !== "" && !validateEmail(email) ? (
                                    <Tooltip title="Not a valid email">
                                      <span>
                                        <InputAdornment position="end">
                                          <ErrorRounded color="error" />
                                        </InputAdornment>
                                      </span>
                                    </Tooltip>
                                  ) : email !== "" && validateEmail(email) ? (
                                    <Tooltip title="Valid email">
                                      <span>
                                        <InputAdornment position="end">
                                          <DoneRounded color="success" />
                                        </InputAdornment>
                                      </span>
                                    </Tooltip>
                                  ) : null
                                ),
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} pr={1} mb={3}>
                          <FormControl sx={{ width: "100%" }}>
                            <MDInput
                              variant="standard"
                              label="Phone"
                              placeholder="Your phone number"
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                              value={phone}
                              required
                              onChange={(e) => setPhone(e.target.value)}
                              InputProps={{
                                endAdornment: (
                                  phone !== "" && !validatePhone(phone) ? (
                                    <Tooltip title="Not a valid phone number">
                                      <span>
                                        <InputAdornment position="end">
                                          <ErrorRounded color="error" />
                                        </InputAdornment>
                                      </span>
                                    </Tooltip>
                                  ) : phone !== "" && validatePhone(phone) ? (
                                    <Tooltip title="Valid phone number">
                                      <span>
                                        <InputAdornment position="end">
                                          <DoneRounded color="success" />
                                        </InputAdornment>
                                      </span>
                                    </Tooltip>
                                  ) : null
                                ),
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} pr={1} mb={3}>
                          <FormControl sx={{ width: "100%" }}>
                            <MDInput
                              variant="standard"
                              label="Your message"
                              placeholder="I want to say that..."
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                              multiline
                              rows={6}
                              required
                              value={message}
                              onChange={(e) => setMessage(e.target.value)}
                            />
                            {message.length < 251 && (
                              <MDTypography variant="button">
                                {250 - message.length} characters left
                              </MDTypography>
                            )}
                            {message.length >= 251 && (
                              <MDTypography variant="button" color="warning">
                                WOAH!!! Too many characters
                              </MDTypography>

                            )}
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        md={6}
                        justifyContent="flex-end"
                        textAlign="right"
                        ml="auto"
                      >

                        <MDButton variant="gradient" color="info"
                          disabled={
                            name === "" ||
                            email === "" ||
                            phone === "" ||
                            message === "" ||
                            !validateEmail(email) ||
                            !validatePhone(phone) ||
                            message.length > 250
                          }
                          onClick={async (e) => {
                            e.preventDefault();
                            const result = await fetch("/api/send-contact-section-form", {
                              method: "POST",
                              headers: { "Content-Type": "application/json" },
                              body: JSON.stringify({
                                name: name,
                                email: email,
                                message: message,
                                phone: phone,
                                pathname: router.pathname,
                              }),
                            })
                              .then((response) => response.json())
                              .then((response) => response.message)
                              .catch(() => "error");
                            if (result !== "success") {
                              setSnackbar({ title: "Error!", content: "Message didn't go through. Try again. ", color: "info" })
                            } else {
                              setSnackbar({ title: "Success!", content: "Message sent successfully! Yay!", color: "success" })
                            }
                          }}
                        >
                          Send Message
                        </MDButton>
                      </Grid>
                    </MDBox>
                  </MDBox>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={5}
                  position="relative"
                  px={0}
                  sx={{ overflow: 'hidden', paddingLeft: "0 !important", paddingTop: "0 !important" }}
                >
                  <MDBox sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    overflow: 'hidden',
                    zIndex: 0,
                    height: controller.mobileView ? 428 : 876,
                    transform: controller.mobileView ? 'scale(1.2)' : 'scale(1)',
                    width: "100%"
                  }}
                  >
                    <Image
                    src={bgImage}
                    alt="background picture of a cabana"
                    width={controller.mobileView ? 500 : 640}
                    height={controller.mobileView ? 500 : 876}
                    style={{  zIndex: 10, top: 0, left: 0, objectFit: "cover", objectPosition: "center" }}
                    sizes="(max-width: 768px) 90vw, (max-width: 1000px) 24vw, 24vw"
                  />
                  </MDBox>
                  <MDBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    height="100%"
                    sx={{ position: 'relative', zIndex: 4 }}
                  >
                    <MDBox py={6} pl={6} pr={{ xs: 6, sm: 12 }} my="auto">
                      <MDTypography variant="h3" color="white" mb={1} className={robotoSlab.className}>
                        Contact Information
                      </MDTypography>
                      <MDTypography variant="body2" color="white" opacity={0.8} mb={3}>
                        Fill up the form and our Team will get back to you within 24 hours.
                      </MDTypography>
                      <MDBox display="flex" p={1}>
                        <MDTypography variant="button" color="white">
                          <i className="fas fa-phone" aria-hidden="true" />
                        </MDTypography>
                        <MDTypography
                          component="span"
                          variant="button"
                          color="white"
                          opacity={0.8}
                          ml={2}
                          fontWeight="regular"

                        >
                          <a href="tel:631-424-5544" style={{ color: "white" }}>(631) 424-5544</a>
                        </MDTypography>
                      </MDBox>
                      <MDBox display="flex" color="white" p={1}>
                        <MDTypography variant="button" color="white">
                          <i className="fas fa-envelope" aria-hidden="true" />
                        </MDTypography>
                        <MDTypography
                          component="span"
                          variant="button"
                          color="white"
                          opacity={0.8}
                          ml={2}
                          fontWeight="regular"
                        >
                          <a href="mailto:lenny@autumnleaflandscape.com" style={{ color: "white" }}>lenny@autumnleaflandscape.com</a>
                        </MDTypography>
                      </MDBox>
                      <MDBox display="flex" color="white" p={1}>
                        <MDTypography variant="button" color="white">
                          <Language />
                        </MDTypography>
                        <MDTypography
                          href="https://www.google.com/maps/dir//Autumn+Leaf+Landscape+Design,+100+Centershore+Rd,+Centerport,+NY+11721/@40.8914884,-73.3773813,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x89e826770c363025:0x99c88aad47373ab6!2m2!1d-73.3743343!2d40.8922589"
                          target="_blank"
                          rel="noreferrer noopener"
                          component="a"
                          variant="button"
                          color="white"
                          opacity={0.8}
                          ml={2}
                          fontWeight="regular"
                        >
                          100 Centershore Road,<br />
                          Centerport, NY 11721
                        </MDTypography>
                      </MDBox>
                      <MDBox mt={3}>
                        <MDButton variant="text" color="white" size="large" iconOnly
                          component="a"
                          href={config.FbURL}
                          target="_blank"
                          rel="noreferrer noopener"
                          aria-label="Autumn Leaf's Facebook Profile"
                        >
                          <Facebook />
                        </MDButton>
                        <MDButton variant="text" color="white" size="large" iconOnly
                          component="a"
                          href={config.InstagramURL}
                          target="_blank"
                          rel="noreferrer noopener"
                          aria-label="Autumn Leaf's Instagram Profile"
                        >
                          <Instagram />
                        </MDButton>
                        <MDButton variant="text" color="white" size="large" iconOnly
                          component="a"
                          href={config.YoutubeURL}
                          target="_blank"
                          rel="noreferrer noopener"
                          aria-label="Autumn Leaf's YouTube Profile"
                        >
                          <YouTube />
                        </MDButton>
                        <MDButton variant="text" color="white" size="large" iconOnly
                          component="a"
                          href={config.TwitterURL}
                          target="_blank"
                          rel="noreferrer noopener"
                          aria-label="Autumn Leaf's Twitter Profile"
                        >
                          <Twitter />
                        </MDButton>
                        <MDButton variant="text" color="white" size="large" iconOnly
                          component="a"
                          href={config.frontURL}
                          target="_blank"
                          aria-label="Autumn Leaf's Website"
                        >
                          <Language />
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
          </Grid>

        </Container>
      </MDBox>
      {Object.keys(snackbar).length !== 0 && (
        <MDSnackbar
          title={snackbar.title || ""}
          content={snackbar.content || ""}
          dateTime={formatTimeFriendly()}
          color={snackbar.color || "dark"}
          open={true}
          close={() => setSnackbar({})}
        />
      )}
    </>
  );
}

export default ContactUs;